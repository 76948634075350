/**
 * @Author: Calvin Green <calvin>
 * @Date:   2018-06-08T11:06:39+02:00
 * @Email:  calvin@talentec.co.za
 * @Filename: en-ZA.js
 * @Last modified by:   calvin
 * @Last modified time: 2018-07-04T11:28:43+02:00
 * @Copyright: Talentec 2018
 */



/*English (South Africa) locale*/
//eslint-disable-next-line no-undef
webix_plugin.i18n.locales["en-ZA"] = {
	groupDelimiter:" ",
	groupSize:3,
	decimalDelimiter:".",
	decimalSize:2,
	dateFormat:"%d/%m/%Y",
	timeFormat:"%h:%i %a",
	longDateFormat:"%d %F %Y",
	fullDateFormat:"%d %F %Y %h:%i %a",
	am:["am","AM"],
	pm:["pm","PM"],
	price:"R {obj}",
	priceSettings:{
		groupDelimiter:" ",
		groupSize:3,
		decimalDelimiter:".",
		decimalSize:0,
		align:"right"
	},
	calendar:{
		monthFull:[],
		monthShort:[],
		dayFull:[],
		dayShort:[]
	}
};
